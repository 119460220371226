import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  QuestionnaireResponse,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
  Reference,
  ServiceRequest,
  Task
} from '@medplum/fhirtypes'
import { useQuery } from '@tanstack/react-query'
import { useMedplum } from '@medplum/react'
import {
  extractAnswers,
  getAnswerValuesFromItems,
  getShouldFinalizeAssessment,
  updateObjectByLinkId
} from 'components/Assessments/helpers'
import { removeNullValues } from 'utils/helpers'
import { rebuildResponseFromFlat } from 'components/Patients/Assessments/CreateAssessment/helpers'
import { SocAssessmentQuestionnaireItem } from 'components/Assessments/SocAssessment/types/questionnaire'
import { AssessmentStatus, COMPLETION_STATUSES } from 'fhir/QuestionnaireResponse/constants'
import { AssessmentType } from 'components/Clinical/Assessments/constants'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { QueryKeys } from 'utils/query.constants'
import { LOINC_CODE_PRIOR_AUTH_REQUEST } from 'fhir/ServiceRequest/categories'
import { OrderType } from 'components/Patients/Orders/CreateOrder/constants'
import AssessmentLayoutContext from './AssessmentLayoutContext'
import { useQAAssessmentContext } from './QAAssessmentContext'
import AccessPolicyContext from './AccessPolicyContext'
import PatientContext from './PatientContext'

export interface LinkIdWithItem {
  linkId: string
  item: QuestionnaireResponseItem
}

interface AssessmentContextProps {
  children: React.ReactNode
  assessmentId: string
  assessmentType: AssessmentType
  assessmentStatus: string
  initialSavedResponse: QuestionnaireResponse
  initialFlatAnswers: Record<string, QuestionnaireResponseItem>
  flowStage: Task | null
}

interface AssessmentContextType {
  assessmentId: string | null
  assessmentType: AssessmentType | null // Type of assessment (e.g. 'SOC', 'PAT', 'Recert', etc)
  assessmentStatus: string | null
  assessmentMustBeFinalized: boolean
  cantEditAssessmentFields: boolean // Set of rules(check AssessmentContext.Provider rendering for more info) that determine if the assessment can be edited
  response: QuestionnaireResponse | null // This will hold the entire QuestionnaireResponse state
  setResponse: React.Dispatch<React.SetStateAction<QuestionnaireResponse | null>> // Function to update the response
  answers: Record<string, QuestionnaireResponseItemAnswer> // This will hold the questionnaire answers
  setResponseItems: (newResponseItems: QuestionnaireResponseItem[]) => void // Function that grabs each new item and updates both response and answers accordingly
  flatAnswers: Record<string, QuestionnaireResponseItem> // Structured as linkId: answer for constant-time update
  updateFlatAnswers: (...linkIdsWithAnswers: LinkIdWithItem[]) => void // Updates the flatAnswers structure
  flowStage: Task | null // Stage of a flow where this assessment is being used
}

const AssessmentContext = createContext<AssessmentContextType>({
  assessmentId: null,
  assessmentType: null,
  assessmentStatus: null,
  assessmentMustBeFinalized: true,
  cantEditAssessmentFields: false,
  response: null,
  setResponse: () => {},
  answers: {},
  setResponseItems: () => {},
  flatAnswers: {},
  updateFlatAnswers: () => {},
  flowStage: null
})

export const useAssessmentContext = (): AssessmentContextType => {
  return useContext(AssessmentContext)
}

export const AssessmentContextProvider: FC<AssessmentContextProps> = ({
  children,
  assessmentId,
  assessmentType,
  assessmentStatus,
  initialSavedResponse,
  initialFlatAnswers,
  flowStage
}) => {
  const medplum = useMedplum()

  const { patientId } = useContext(PatientContext)
  const { canReadWrite } = useContext(AccessPolicyContext)
  const { canWrite } = canReadWrite('QuestionnaireResponse')

  const { debugLogs } = useFeatureFlags()

  const {
    assessmentQAStatus: { inQA }
  } = useQAAssessmentContext()

  const { hasRoleToEdit } = useContext(AssessmentLayoutContext)

  const cantEditAssessmentFields =
    !canWrite ||
    inQA ||
    !hasRoleToEdit ||
    COMPLETION_STATUSES.includes(assessmentStatus as AssessmentStatus)

  const { data: priorAuthRequests } = useQuery({
    queryKey: [QueryKeys.SERVICE_REQUESTS, patientId, OrderType.PRIOR_AUTH_REQUEST],
    queryFn: async (): Promise<ServiceRequest[]> => {
      return await medplum.searchResources('ServiceRequest', {
        patient: `Patient/${patientId}`,
        code: LOINC_CODE_PRIOR_AUTH_REQUEST
      })
    }
  })

  // When response is updated it will trigger an autosave in the Assessment specific controller (i.e. PATAssessmentController / SOCAssessmentController)
  const [response, setResponse] = useState<QuestionnaireResponse | null>(initialSavedResponse)

  const [flatAnswers, setFlatAnswers] =
    useState<Record<SocAssessmentQuestionnaireItem, QuestionnaireResponseItem>>(initialFlatAnswers)

  const answers = useMemo(() => {
    return removeNullValues(extractAnswers(response ?? undefined)) as Record<
      string,
      QuestionnaireResponseItemAnswer
    >
  }, [response])

  const setResponseItems = useCallback(
    (newResponseItems: QuestionnaireResponseItem[]): void => {
      if (cantEditAssessmentFields) return

      setResponse((prevResponse: QuestionnaireResponse) => {
        const updatedResponse = newResponseItems.reduce(
          (acc, item) => {
            if (acc.item && typeof item.linkId === 'string') {
              const updatedItem = updateObjectByLinkId(acc.item, item.linkId, item)
              return { ...acc, item: updatedItem }
            }
            return acc
          },
          {
            ...prevResponse,
            resourceType: 'QuestionnaireResponse' as const // to workaround the janky fhir type system
          }
        )

        return updatedResponse
      })
    },
    [cantEditAssessmentFields]
  )

  // SOC version
  const updateFlatAnswers = useCallback(
    (...linkIdsWithItems: LinkIdWithItem[]) => {
      if (cantEditAssessmentFields || linkIdsWithItems.length === 0) return

      if (debugLogs) {
        console.warn(
          '[AssessmentContext] Updating flat answers with link IDs with items',
          linkIdsWithItems
        )
      }

      const valuesByLinkId = Object.fromEntries(
        linkIdsWithItems.map(({ linkId, item }) => [linkId, item])
      )

      setFlatAnswers((prevFlatAnswers) => {
        return { ...prevFlatAnswers, ...valuesByLinkId }
      })
      setResponse((prevResponse) => {
        if (!prevResponse) {
          return response
        }

        const nestedAnswers = rebuildResponseFromFlat(flatAnswers)
        return { ...prevResponse, item: nestedAnswers }
      })
    },
    [cantEditAssessmentFields, debugLogs, flatAnswers, response]
  )

  const assessmentMustBeFinalized = useMemo(() => {
    const [authorizationSource] = getAnswerValuesFromItems(
      Object.values(flatAnswers),
      SocAssessmentQuestionnaireItem.AUTHORIZATION_AUTHORIZATION_AUTHORIZATION_SOURCE
    ) as (Reference | null)[]

    const authorizationSourceReference = authorizationSource?.reference
    if (!authorizationSourceReference) return true

    const matchingAuthorizationSource = priorAuthRequests?.find(
      (priorAuthRequest) => `ServiceRequest/${priorAuthRequest.id}` === authorizationSourceReference
    )

    const shouldFinalizeAssessment = getShouldFinalizeAssessment({
      assessmentType,
      authorizationSource: matchingAuthorizationSource
    })

    return shouldFinalizeAssessment
  }, [assessmentType, flatAnswers, priorAuthRequests])

  useEffect(() => {
    if (debugLogs) {
      console.warn('[AssessmentContext] Assessment context is reloading...')
    }
  }, [debugLogs])

  return (
    <AssessmentContext.Provider
      value={{
        assessmentId,
        assessmentType,
        assessmentStatus,
        assessmentMustBeFinalized,
        cantEditAssessmentFields,
        response,
        setResponse,
        answers,
        setResponseItems,
        flatAnswers,
        updateFlatAnswers,
        flowStage
      }}
    >
      {children}
    </AssessmentContext.Provider>
  )
}

export default AssessmentContext

// Let's try to keep most of the React Query keys as their related resources names in plural or singular
// depending on the context. For example, if we are fetching a list of questionnaires, the key should be
// 'QUESTIONNAIRES' and if we are fetching a single questionnaire, the key should be 'QUESTIONNAIRE'.
export enum QueryKeys {
  QUESTIONNAIRES = 'QUESTIONNAIRES',
  QUESTIONNAIRE_RESPONSES = 'QUESTIONNAIRE_RESPONSES',
  QUESTIONNAIRE_RESPONSE = 'QUESTIONNAIRE_RESPONSE',
  ASSESSMENT_DEPENDENT_QUESTIONNAIRE_RESPONSES = 'ASSESSMENT_DEPENDENT_QUESTIONNAIRE_RESPONSES',
  ALL_QUESTIONNAIRE_RESPONSES = 'ALL_QUESTIONNAIRE_RESPONSES',
  SERVICE_REQUESTS = 'SERVICE_REQUESTS',
  PRACTITIONERS = 'PRACTITIONERS',
  PRACTITIONER_ROLES = 'PRACTITIONER_ROLES',
  CARE_TEAMS = 'CARE_TEAMS',
  PATIENTS = 'PATIENTS',
  PATIENT_DATA = 'PATIENT_DATA',
  PATIENT_DATA_PATIENT = 'PATIENT_DATA_PATIENT',
  PATIENT_CHART = 'PATIENT_CHART',
  PATIENT_PROFILE = 'PATIENT_PROFILE',
  INTERACTIONS = 'INTERACTIONS',
  HEALTHCARE_SERVICES = 'HEALTHCARE_SERVICES',
  ORGANIZATIONS = 'ORGANIZATIONS',
  ORGANIZATION = 'ORGANIZATION',
  PRACTITIONER_BACKGROUND = 'PRACTITIONER_BACKGROUND',
  PRACTITIONER = 'PRACTITIONER',
  LOCATIONS = 'LOCATIONS',
  TASKS = 'TASKS',
  FLOWS = 'FLOWS',
  DATA_COMPLETENESS_VALIDATION = 'DATA_COMPLETENESS_VALIDATION',
  DIAGNOSES = 'DIAGNOSES',
  MEDICATIONS = 'MEDICATIONS',
  LIVE_PATIENT_RECORD = 'LIVE_PATIENT_RECORD',
  FLOW = 'FLOW',
  STAGE = 'STAGE',
  DOCUMENTS = 'DOCUMENTS',
  ASSESSMENT_COMPLETENESS_VALIDATION = 'ASSESSMENT_COMPLETENESS_VALIDATION',
  INTERVENTIONS = 'INTERVENTIONS',
  ORDER_PATIENT_CHART_VALIDATION = 'ORDER_PATIENT_CHART_VALIDATION',
  CONSENTS = 'CONSENTS',
  APPOINTMENTS = 'APPOINTMENTS',
  CHARTING_VALIDATION = 'CHARTING_VALIDATION',
  CLINICAL_PRACTITIONERS = 'CLINICAL_PRACTITIONERS'
}

import { LOINC_CODE_SYSTEM } from 'fhir/constants'
import { createCodeableConcept } from 'fhir/utils'

export const LOINC_CODE_SOC = '76426-6'
export const LOINC_CODE_CERT_PERIOD = '76432-4'
export const LOINC_CODE_HOLD = '92183-3'
export const LOINC_CODE_DISCHARGE = '52525-3'
export const LOINC_CODE_ROC = '46498-2'
export const LOINC_CODE_ACUTE = '76432-4'
export const LOINC_CODE_VISIT_ORDER = '78030-4'
export const LOINC_CODE_REVISION_OF_PAR_ORDER = '73694-2'
export const LOINC_CODE_PRIOR_AUTH = '52036-1'
export const LOINC_CODE_PRIOR_AUTH_REQUEST = '52036-2'

export const LOINC_CODE_HOME_HEALTH_CARE = '52702-8'
export const LOINC_CODE_GENERAL_ORDER = '93037-0'

// Medication is not an actual LOINC code, this is just used to distinguish
// within our app between ServiceRequest and MedicationRequest.

export const PLACEHOLDER_CODE_MEDICATION = 'MEDICATION_PLACEHOLDER'

export const SERVICE_REQUEST_SOC_CATEGORY = createCodeableConcept({
  system: LOINC_CODE_SYSTEM,
  code: LOINC_CODE_SOC,
  text: 'Start of Care Visit'
})

export const SERVICE_REQUEST_CERT_PERIOD_CATEGORY = createCodeableConcept({
  system: LOINC_CODE_SYSTEM,
  code: LOINC_CODE_CERT_PERIOD,
  text: 'Certification Period'
})

export const SERVICE_REQUEST_HOLD_CATEGORY = createCodeableConcept({
  system: LOINC_CODE_SYSTEM,
  code: LOINC_CODE_HOLD,
  text: 'Hold'
})

export const SERVICE_REQUEST_DISCHARGE_CATEGORY = createCodeableConcept({
  system: LOINC_CODE_SYSTEM,
  code: LOINC_CODE_DISCHARGE,
  text: 'Discharge'
})

export const SERVICE_REQUEST_RESUMPTION_CATEGORY = createCodeableConcept({
  system: LOINC_CODE_SYSTEM,
  code: LOINC_CODE_ROC,
  text: 'Resumption'
})

export const SERVICE_REQUEST_ACUTE_CATEGORY = createCodeableConcept({
  system: LOINC_CODE_SYSTEM,
  code: LOINC_CODE_ACUTE,
  text: 'Acute'
})

export const SERVICE_REQUEST_VISIT_ORDER_CATEGORY = createCodeableConcept({
  system: LOINC_CODE_SYSTEM,
  code: LOINC_CODE_VISIT_ORDER,
  text: 'Visit'
})

export const SERVICE_REQUEST_MEDICATION_CATEGORY = createCodeableConcept({
  system: LOINC_CODE_SYSTEM,
  code: PLACEHOLDER_CODE_MEDICATION,
  text: 'Medication'
})

import {
  Address,
  BaseCareTeamParticipantFragment,
  BaseCareTeamParticipantWithPractitionerFragment,
  BaseCareTeamWithOrganizationFragment,
  BaseCareTeamWithParticipantFragment,
  BaseCareTeamWithPatientFragment,
  BaseCareTeamWithPractitionerFragment,
  BasePatientFragment,
  BasePractitionerFragment,
  ContactPoint,
  HumanName
} from 'generated/graphql'
import {
  AddressForFormDisplay,
  getPrimaryAddress,
  parseAddressForFormDisplay,
  parsePrimaryAddressForFormDisplay
} from 'utils/address'
import { findValueBySystem } from 'utils/helpers'
import { formatPrimaryName, getPrimaryName } from 'utils/names'
import { getValidatedFragment, isValidFragmentType } from 'fhir/utils'
import { castToEnum } from 'utils/enum'
import { CareTeam } from 'store/careTeam'
import { CareTeamMemberRole } from './constants'
import { CareTeamForPatient, PatientCareTeamMember } from './types'

export const selectPrimaryCareTeam = <T extends BaseCareTeamWithOrganizationFragment | null>(
  careTeams: T[]
): T | undefined => {
  return careTeams.at(0)
}

export const selectCareTeamsWithPractitionerRole = <T extends BaseCareTeamWithParticipantFragment>(
  careTeams: T[],
  practitionerId: string,
  careTeamRole: CareTeamMemberRole
): T[] => {
  const careTeamsWithRole = careTeams.filter((c) =>
    c.participant?.find(
      (p) =>
        p.member?.reference === `Practitioner/${practitionerId}` &&
        p.role?.find(
          (r) =>
            r?.text === careTeamRole ||
            r?.coding?.some((c) => c?.code === careTeamRole || c?.display === careTeamRole)
        )
    )
  )

  return careTeamsWithRole
}

export const selectCareTeamParticipantsWithRole = <T extends BaseCareTeamWithParticipantFragment>(
  careTeam: T,
  careTeamRole: CareTeamMemberRole
): BaseCareTeamParticipantFragment[] => {
  const careTeamParticipants = careTeam.participant ?? []

  const careTeamParticipantsWithRole =
    careTeamParticipants
      .filter((p) => !!p)
      .filter((p) =>
        p.role?.find(
          (r) =>
            r?.text === careTeamRole ||
            r?.coding?.some((c) => c?.code === careTeamRole || c?.display === careTeamRole)
        )
      ) ?? []

  return careTeamParticipantsWithRole
}

export const selectCareTeamPractitionersWithRole = <T extends BaseCareTeamWithPractitionerFragment>(
  careTeam: T,
  careTeamRole: CareTeamMemberRole
): BasePractitionerFragment[] => {
  const careTeamParticipants = careTeam.participant ?? []

  const careTeamParticipantsWithRole =
    careTeamParticipants
      .filter((p) => !!p)
      .filter((p) =>
        p.role?.find(
          (r) =>
            r?.text === careTeamRole ||
            r?.coding?.some((c) => c?.code === careTeamRole || c?.display === careTeamRole)
        )
      ) ?? []

  return careTeamParticipantsWithRole
    .map((p) => p.member?.resource)
    .filter((p): p is BasePractitionerFragment => isValidFragmentType<BasePractitionerFragment>(p))
}

export const selectCareTeamPractitioners = <T extends BaseCareTeamWithPractitionerFragment>(
  careTeam: T
): BasePractitionerFragment[] => {
  const practitioners = careTeam?.participant?.map((p) => p?.member?.resource) ?? []

  return practitioners.filter((p): p is BasePractitionerFragment =>
    isValidFragmentType<BasePractitionerFragment>(p)
  )
}

export const selectCareTeamPractitionerFromParticipant = (
  participant: BaseCareTeamParticipantWithPractitionerFragment
): BasePractitionerFragment | null => {
  return getValidatedFragment<BasePractitionerFragment>(participant?.member?.resource)
}

export const selectCareTeamParticipantRole = (
  participant: BaseCareTeamParticipantFragment
): CareTeamMemberRole | undefined => {
  const role = participant.role
    ?.map((r) => r?.coding?.find((c) => c?.code || c?.display) ?? r?.text)
    ?.at(0)

  if (typeof role === 'string') {
    return castToEnum(CareTeamMemberRole, role)
  }

  return (
    castToEnum(CareTeamMemberRole, role?.code ?? undefined) ??
    castToEnum(CareTeamMemberRole, role?.display ?? undefined)
  )
}

export const selectCareTeamPatient = <T extends BaseCareTeamWithPatientFragment>(
  careTeam: T
): BasePatientFragment | null => {
  const patient = getValidatedFragment<BasePatientFragment>(careTeam?.subject?.resource)

  return patient
}

export const selectOrderingPhysicianInfo = (
  careTeam: CareTeam | null,
  orderingPhysicianId?: string
): {
  id?: string
  name?: HumanName
  address?: Address
  email?: string
  fax?: string
  phone?: string
} => {
  if (!careTeam) return {}

  let orderingPhysician: BasePractitionerFragment | undefined
  if (orderingPhysicianId) {
    const orderingPhysicians = careTeam.orderingPhysicians ?? []
    orderingPhysician = orderingPhysicians.find((p) => p.id === orderingPhysicianId)
  } else {
    const orderingPhysicians = careTeam.orderingPhysicians ?? []
    orderingPhysician = orderingPhysicians.at(0)
  }
  const orderingPhysicianTelecom =
    orderingPhysician?.telecom?.filter((t): t is ContactPoint => !!t) ?? undefined

  // TODO: we really have to do something about these terrible generated type unions in graphql
  const addresses = orderingPhysician?.address?.filter((a): a is Address => !!a) ?? []
  const name = getPrimaryName(orderingPhysician?.name ?? []) ?? undefined

  return {
    id: orderingPhysician?.id ?? undefined,
    name,
    address: getPrimaryAddress(addresses) ?? undefined,
    email: findValueBySystem(orderingPhysicianTelecom ?? [], 'email'),
    fax: findValueBySystem(orderingPhysicianTelecom ?? [], 'fax'),
    phone: findValueBySystem(orderingPhysicianTelecom ?? [], 'phone')
  }
}

export function selectCareTeamMember(
  person: {
    name?: HumanName[] | HumanName | null
    address?: Address[] | Address | null
    telecom?: ContactPoint[] | null
    id?: string | null
  },
  role: CareTeamMemberRole
): PatientCareTeamMember | null {
  if (!person || !role) return null

  const names = [person.name].flat().filter((n): n is HumanName => !!n)
  const formattedName = formatPrimaryName(names)

  const phone = person.telecom ? findValueBySystem(person.telecom, 'phone') : null
  const address = Array.isArray(person.address)
    ? parsePrimaryAddressForFormDisplay(person.address)
    : parseAddressForFormDisplay(person.address)

  return {
    id: person.id,
    role: role,
    name: formattedName,
    phone: phone,
    address: address,
    resource: person
  }
}

export function transformFormAddress(
  address: AddressForFormDisplay | null | undefined
): Address | null {
  if (!address) return null
  const newAddress: Address = {
    city: address?.city,
    state: address?.state,
    line: address?.line ? [address.line] : null,
    postalCode: address?.postalCode,
    text: address?.text
  }

  return newAddress
}

export function selectCareTeamForPatient(
  careTeams: (BaseCareTeamWithParticipantFragment & BaseCareTeamWithOrganizationFragment)[],
  patient: BasePatientFragment
): CareTeamForPatient | null {
  // Assume there is only ever one care team per patient
  const careTeam = selectPrimaryCareTeam(careTeams)
  if (!careTeam) return null

  const practitionerMembers = (careTeam.participant
    ?.map((participant: any) =>
      selectCareTeamMember(participant?.member?.resource, participant?.role?.at(0)?.text)
    )
    .filter((member) => member !== null) ?? []) as PatientCareTeamMember[]

  const contactMembers = (patient.contact
    ?.map((contact) => {
      const relationship = contact.relationship?.at(0)
      return selectCareTeamMember(contact, (relationship?.text as CareTeamMemberRole) ?? '')
    })
    .filter((member) => member !== null) ?? []) as PatientCareTeamMember[]

  return {
    id: careTeam.id,
    name: careTeam.name,
    members: [...practitionerMembers, ...contactMembers]
  }
}

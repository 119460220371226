import { AssessmentType } from 'components/Clinical/Assessments/constants'
import {
  LOINC_CODE_CERT_PERIOD,
  LOINC_CODE_DISCHARGE,
  LOINC_CODE_GENERAL_ORDER,
  LOINC_CODE_HOLD,
  LOINC_CODE_PRIOR_AUTH,
  LOINC_CODE_PRIOR_AUTH_REQUEST,
  LOINC_CODE_REVISION_OF_PAR_ORDER,
  LOINC_CODE_ROC,
  LOINC_CODE_SOC
} from 'fhir/ServiceRequest/categories'

export enum OrderType {
  SOC = 'soc',
  CERTIFICATION = 'certification',
  ROC = 'roc',
  HOLD = 'hold',
  DISCHARGE = 'discharge',
  REVISION_OF_PAR = 'revision-of-par',
  PRIOR_AUTH = 'prior-auth',
  PRIOR_AUTH_REQUEST = 'prior-auth-request',
  GENERAL = 'general'
  // ACUTE = 'acute',
  // VISIT = 'visit',
  // MEDICATION = 'medication'
}

// Need to transform values because the value (the LOINC code)
// is actually the same for both Acute and Cert Period
export const CODES_BY_ORDER_TYPE: Record<
  OrderType,
  { label: string; code: string; disabled?: boolean }
> = {
  [OrderType.SOC]: {
    label: 'Start of Care Visit',
    code: LOINC_CODE_SOC
  },
  [OrderType.CERTIFICATION]: {
    label: 'Certification Period',
    code: LOINC_CODE_CERT_PERIOD
  },
  [OrderType.HOLD]: {
    label: 'Hold',
    code: LOINC_CODE_HOLD
  },
  [OrderType.DISCHARGE]: {
    label: 'Discharge',
    code: LOINC_CODE_DISCHARGE
  },
  [OrderType.REVISION_OF_PAR]: {
    label: 'Revision of PAR',
    code: LOINC_CODE_REVISION_OF_PAR_ORDER,
    // NOTE (Jonas): Revision of PAR cannot be created or deleted by the user
    disabled: true
  },
  [OrderType.ROC]: {
    label: 'Resumption of Care Visit',
    code: LOINC_CODE_ROC
  },
  [OrderType.PRIOR_AUTH]: {
    label: 'Prior Authorization',
    code: LOINC_CODE_PRIOR_AUTH
  },
  [OrderType.PRIOR_AUTH_REQUEST]: {
    label: 'Prior Authorization Request',
    code: LOINC_CODE_PRIOR_AUTH_REQUEST
  },
  [OrderType.GENERAL]: {
    label: 'General',
    code: LOINC_CODE_GENERAL_ORDER
  }
  // [OrderType.ACUTE]: {
  //   label: 'Acute',
  //   code: LOINC_CODE_ACUTE
  // },
  // [OrderType.VISIT]: {
  //   label: 'Visit',
  //   code: LOINC_CODE_VISIT_ORDER
  // },
  // [OrderType.MEDICATION]: {
  //   label: 'Medication',
  //   code: PLACEHOLDER_CODE_MEDICATION,
  //   disabled: true
  // }
}

export const ORDER_CODES_BY_ASSESSMENT_TYPE: Record<
  | AssessmentType.SOC
  | AssessmentType.RECERT
  | AssessmentType.REVISION_OF_PAR
  | AssessmentType.DISCHARGE
  | AssessmentType.ROC
  | AssessmentType.SUPERVISORY,
  { label: string; code: string; disabled?: boolean } | null
> = {
  [AssessmentType.SOC]: CODES_BY_ORDER_TYPE[OrderType.SOC],
  [AssessmentType.RECERT]: CODES_BY_ORDER_TYPE[OrderType.CERTIFICATION],
  [AssessmentType.REVISION_OF_PAR]: null,
  [AssessmentType.DISCHARGE]: CODES_BY_ORDER_TYPE[OrderType.DISCHARGE],
  [AssessmentType.ROC]: CODES_BY_ORDER_TYPE[OrderType.ROC],
  [AssessmentType.SUPERVISORY]: null
  // [OrderType.ACUTE]: {
  //   label: 'Acute',
  //   code: LOINC_CODE_ACUTE
  // },
  // [OrderType.VISIT]: {
  //   label: 'Visit',
  //   code: LOINC_CODE_VISIT_ORDER
  // },
  // [OrderType.MEDICATION]: {
  //   label: 'Medication',
  //   code: PLACEHOLDER_CODE_MEDICATION,
  //   disabled: true
  // }
}

export const ORDER_TYPE_OPTIONS = Object.entries(CODES_BY_ORDER_TYPE).map(([orderType, info]) => ({
  key: orderType,
  ...info,
  value: orderType
}))

export enum ABBYCARE_NOTE_CODE {
  RECEIVED_FROM = 'received_from'
}

export enum HoldOrderReasons {
  AWAITING_INFORMATION = 'awaiting_information',
  PATIENT_UNAVAILABLE = 'patient_unavailable',
  CHANGE_IN_MEDICAL_CONDITION = 'change_in_medical_condition',
  INSURANCE_ISSUES = 'insurance_issues',
  OTHER = 'other'
}

export const HOLD_ORDER_REASONS = {
  [HoldOrderReasons.AWAITING_INFORMATION]: 'Awaiting Information',
  [HoldOrderReasons.PATIENT_UNAVAILABLE]: 'Patient Unavailable',
  [HoldOrderReasons.CHANGE_IN_MEDICAL_CONDITION]: 'Change in Medical Condition',
  [HoldOrderReasons.INSURANCE_ISSUES]: 'Insurance Issues',
  [HoldOrderReasons.OTHER]: 'Other'
}

const HOLD_ORDER_TYPE_OPTIONS = Object.entries(HOLD_ORDER_REASONS).map(([value, label]) => ({
  label,
  value
}))

enum DischargeOrderReasons {
  IMPROVEMENT = 'improvement',
  TRANSFERRED_TO_ANOTHER_FACILITY = 'transferred_to_another_facility',
  DISCHARGE_AGAINST_MEDICAL_ADVICE = 'discharge_against_medical_advice',
  FINANCIAL_REASON = 'financial_reason',
  PATIENT_REQUEST = 'patient_request',
  OTHER = 'other'
}

export const DISCHARGE_ORDER_REASONS = {
  [DischargeOrderReasons.IMPROVEMENT]: 'Improvement',
  [DischargeOrderReasons.TRANSFERRED_TO_ANOTHER_FACILITY]: 'Transferred to Another Facility',
  [DischargeOrderReasons.DISCHARGE_AGAINST_MEDICAL_ADVICE]: 'Discharge Against Medical Advice',
  [DischargeOrderReasons.FINANCIAL_REASON]: 'Financial Reason',
  [DischargeOrderReasons.PATIENT_REQUEST]: 'Patient Request',
  [DischargeOrderReasons.OTHER]: 'Other'
}

const DISCHARGE_ORDER_TYPE_OPTIONS = Object.entries(DISCHARGE_ORDER_REASONS).map(
  ([value, label]) => ({
    label,
    value
  })
)

export const ORDER_REASON_CODES_BY_ORDER_TYPE = {
  [OrderType.HOLD]: HOLD_ORDER_REASONS,
  [OrderType.DISCHARGE]: DISCHARGE_ORDER_REASONS
}

export const ORDER_REASON_OPTIONS_BY_ORDER_TYPE = {
  [OrderType.HOLD]: HOLD_ORDER_TYPE_OPTIONS,
  [OrderType.DISCHARGE]: DISCHARGE_ORDER_TYPE_OPTIONS
}

export enum ResourceValidationMessage {
  ROC_NO_VALID_REQUIRED_HOLD_ORDER = 'Patient must have a completed Hold order with an end date.',
  PRIOR_AUTHORIZATION_NO_PRIOR_AUTHORIZATION_REQUEST_ORDER = 'Patient must have a Prior Authorization Request order.'
}

export const CUSTOM_ORDER_VALIDATIONS = [
  OrderType.CERTIFICATION,
  OrderType.REVISION_OF_PAR,
  OrderType.HOLD,
  OrderType.DISCHARGE,
  OrderType.ROC
]

export enum OrderUnitsItemType {
  AUTHORIZED_UNITS = 'authorized',
  REVISED_UNITS = 'revised'
}

export const AUTHORIZATION_TYPE_ORDERS = [OrderType.PRIOR_AUTH, OrderType.PRIOR_AUTH_REQUEST]

import { AssessmentStatus } from 'fhir/QuestionnaireResponse/constants'

export const fuseOptionKeys = [
  'name',
  'source.display',
  'author.display',
  'subject.display',
  'jursidiction.coding.display'
]

export const routeMyAssessments = '/clinical/assessments/my-assessments'
export const routeAllAssessments = '/clinical/assessments/all-assessments'

export enum AssessmentType {
  SOC = 'soc',
  PAT = 'pat',
  RECERT = 'recert',
  REVISION_OF_PAR = 'revision-of-par',
  SUPERVISORY = 'supervisory',
  // DELEGATION = 'delegation',
  // ACUTE = 'acute',
  DISCHARGE = 'discharge',
  ROC = 'roc',
  SERVICE_PLAN = 'service-plan'
}

export const CareAssessmentTypes = [
  AssessmentType.SOC,
  AssessmentType.RECERT,
  AssessmentType.REVISION_OF_PAR,
  AssessmentType.DISCHARGE,
  AssessmentType.ROC
]

export const AssessmentTypeLabel = {
  [AssessmentType.SOC]: 'Start of Care',
  [AssessmentType.PAT]: 'Scoring (PAT)',
  [AssessmentType.RECERT]: 'Recertification',
  [AssessmentType.REVISION_OF_PAR]: 'Revision of PAR',
  [AssessmentType.SUPERVISORY]: 'Supervisory',
  [AssessmentType.DISCHARGE]: 'Discharge',
  [AssessmentType.ROC]: 'Resumption of Care',
  [AssessmentType.SERVICE_PLAN]: 'Service Plan'
}

export const AssessmentStatusLabel = {
  [AssessmentStatus.IN_PROGRESS]: 'Draft',
  [AssessmentStatus.PENDING_QA]: 'Pending QA',
  [AssessmentStatus.PENDING_QA_EDITS]: 'Corrections Needed',
  [AssessmentStatus.PENDING_APPROVAL]: 'Pending Approval',
  [AssessmentStatus.COMPLETED]: 'Completed'
}

export const statusColorMap = {
  draft: { bg: '#F4F3FF', text: '#5925DC' },
  'pending qa': { bg: '#FFFAEB', text: '#B54708' },
  'corrections needed': { bg: '#FDF4FF', text: '#9000A3' },
  'pending approval': { bg: '#FDF4FF', text: '#9000A3' },
  completed: { bg: '#ECFDF3' }
}
